





















import { Component, Prop, Vue } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import store from "@/store";
import moment from "moment";
import dateHelper from "Utilities/date-helper";
//  types
import { ExtendedVessel } from "@/types/Vessel";
import { IDataSerie } from "@/types/highcharts/dataSerie";
import { TrendPeriodMeta } from "@/types/TrendPeriodMeta";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import VesselEventsModule from "@/store/clients/VesselEvents.module";

const Vessels = getModule(VesselsModule, store);
const VesselEvents = getModule(VesselEventsModule, store);

@Component({
  components: {
    highcharts: Chart,
  },
})
export default class GraphCard extends Vue {
  @Prop({ default: true }) loading!: boolean;
  @Prop({ required: true }) readonly trendPeriodMeta!: TrendPeriodMeta;

  chart!: any;
  chartLoaded = false;

  //  @Getters
  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get benchmarkingZone(): any {
    const benchmarkZone: any = {
      from: null,
      to: null,
    };
    if (this.trendPeriodMeta.benchmark.start == null && this.trendPeriodMeta.benchmark.end == null) return benchmarkZone;
    benchmarkZone.from = moment(this.trendPeriodMeta.benchmark.start).valueOf();
    if (!this.trendPeriodMeta.benchmark.end) {
      benchmarkZone.to = moment.utc();
    } else {
      benchmarkZone.to = moment(this.trendPeriodMeta.benchmark.end).valueOf();
    }

    return benchmarkZone;
  }

  get ChartOptions(): unknown {
    if (!this.chartLoaded) return {};

    const options = {
      time: {
        useUTC: false,
      },
      chart: {
        type: "line",
        zoomType: "x",
        spacingRight: 20,
        marginTop: 55, //to fit tooltip
        animation: false,
        spacingTop: 20,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      title: {
        text: null,
      },

      xAxis: {
        type: "datetime",
        gridLineWidth: 1,
        plotBands: [
          {
            id: "benchmarkPeriod",
            from: this.benchmarkingZone.from,
            to: this.benchmarkingZone.to,
            color: "rgba(0,0,255,0.2)",
            zIndex: 1,
            label: {
              text: "Benchmark period",
            },
          },
        ],
        plotLines: [
          {
            id: "benchmarkPeriodEnd",
            value: this.benchmarkingZone.to,
            color: "rgb(0,0,255)",
            zIndex: 2,
            width: 2,
          },
          ...this.eventPlotLines,
        ],
        events: {
          setExtremes: (e: any) => {
            var cmax = 50;
            var cmin = -50;

            setTimeout(() => {
              //the documented recommended way of determining if reset zoom was clicked does not work
              //(e.min and e.max are supposed to be undefined, but aren't, so we use
              //this other way where we check if zoom button exists or not)
              if (this.chart.resetZoomButton) {
                this.chart.yAxis[0].setExtremes();
              } else {
                this.chart.yAxis[0].update({ min: cmin, max: cmax });
              }
            }, 0);
          },
        },
        title: {
          text: null,
        },
        labels: {
          y: 35,
        },
      },
      yAxis: {
        title: {
          text: "Percent (%)",
        },
        gridLineColor: "#aaaaaa",
        tickPixelInterval: 10,
        min: -50,
        max: 50,
        plotBands: this.plotBands,
        // plotLines: this.plotLines,
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<small>{point.key}</small><br>",
        pointFormat: "Value: <strong>{point.y}</strong>",
        valueDecimals: 1,
        valueSuffix: "%",
        xDateFormat: "%d. %b, %Y",
      },
      plotOptions: {
        series: {
          zIndex: 1,
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 2,
            lineWidth: 0,
            lineColor: "#000000",
            fillColor: "#e60000",
          },
        },
      },
      exporting: {
        enabled: false,
        filename: this.vessel?.name,
        chartOptions: {
          title: {
            text: `Ship speed loss - ${this.vessel?.name}`,
            style: {
              width: "450px",
            },
          },
        },
      },
      series: this.series,
    };

    return options;
  }

  get series(): IDataSerie[] {
    if (!this.trendPeriodMeta.speedLossHistory.length) return [] as IDataSerie[];

    const series: IDataSerie[] = [this.speedLossPoints, this.trendLine, this.benchmarkLine, this.baseline];

    return series;
  }

  get speedLossPoints(): IDataSerie {
    return {
      name: "Long trend",
      type: "scatter",
      zIndex: 1,
      enableMouseTracking: true,
      marker: {
        symbol: "circle",
      },
      // tooltip: {
      //   valueDecimals: 1,
      //   valueSuffix: "%",
      // },
      tooltip: {
        useHTML: true,
        headerFormat: "<small>{point.key}</small><br>",
        pointFormat: "Value: <strong>{point.y}</strong>",
        valueDecimals: 1,
        valueSuffix: "%",
        xDateFormat: "%d. %b, %Y",
      },
      data: this.trendPeriodMeta.speedLossHistory.map(item => [new Date(this.formatDatetimeToUTC(item.timestamp)).getTime(), item.speedLossPercent]),
    };
  }

  get trendLine(): IDataSerie {
    return {
      name: `Trendline (${moment(this.trendPeriodMeta.fromDate).format("DD.MMM YYYY")})`,
      type: "line",
      color: "#008000",
      lineWidth: 3,
      zIndex: 3,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
      tooltip: {
        valueDecimals: 1,
        valueSuffix: "%",
      },
      data: [
        [Date.parse(`${this.trendPeriodMeta.fromDate}`), this.trendPeriodMeta.trendLine.b],
        [Date.parse(`${this.trendPeriodMeta.toDate}`), this.trendPeriodMeta.trendEndValue],
      ],
    };
  }

  get benchmarkLine(): IDataSerie {
    return {
      name: "Benchmark",
      type: "line",
      dashStyle: "dash",
      color: "#0000FF",
      enableMouseTracking: false,
      zIndex: 2,
      marker: {
        enabled: false,
      },
      data: [
        [Date.parse(`${this.trendPeriodMeta.fromDate}`), this.trendPeriodMeta.benchmark.level],
        [Date.parse(`${this.trendPeriodMeta.toDate}`), this.trendPeriodMeta.benchmark.level],
      ],
    };
  }

  get baseline(): IDataSerie {
    return {
      name: "Baseline",
      type: "line",
      color: "#000000",
      lineWidth: 1,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
      data: [
        [Date.parse(`${this.trendPeriodMeta.fromDate}`), 0],
        [Date.parse(`${this.trendPeriodMeta.toDate}`), 0],
      ],
    };
  }

  get plotBands(): any {
    return [
      {
        name: "pb-green",
        color: "rgba(69,186,69,0.5)",
        from: this.greenZoneEnds,
        to: 100,
      },
      {
        id: "pb-yellow",
        color: "rgba(226,226,29,0.5)",
        from: this.greenZoneEnds,
        to: this.yellowZoneEnds,
      },
      {
        name: "pb-red",
        color: "rgba(196,59,59,0.5)",
        from: -100,
        to: this.yellowZoneEnds,
      },
    ];
  }

  get greenZoneEnds(): number {
    return this.trendPeriodMeta.benchmark.level - 5;
  }

  get yellowZoneEnds(): number {
    return this.greenZoneEnds - 5;
  }

  get infoEvents(): any[] {
    return VesselEvents.infoEvents;
  }

  //  @Methods

  chartReady(chart: any): void {
    this.chart = chart;
    this.chartLoaded = true;
    this.chart.update(this.ChartOptions, true);
    setTimeout(() => this.chart.yAxis[0].setExtremes(-50, 50));
  }

  formatDatetimeToUTC(datetime: any): any {
    datetime = datetime.split("+");
    return datetime[0].endsWith("z") || datetime[0].endsWith("Z") ? datetime[0] : datetime[0] + "Z";
  }

  convertTimestamp(timestamp: number): string {
    return dateHelper.getFormatedDateTimeString(timestamp);
  }

  eventIcon(eventType: string): string {
    return eventType === "TrendEvent" ? "mdi-alpha-t-circle" : "mdi-alpha-i-circle";
  }

  addEventLabels(): void {
    this.infoEvents.forEach(event => {
      const html = `
          <i class="plot-line-label-icon mdi ${this.eventIcon(event.type)}"></i>
          <div class="plot-line-tooltip">
            <p><b>${this.convertTimestamp(event.timestamp)}</b></p>
            <p>${event.name}</p>
          </div>
        `;
      this.chart.xAxis[0].addPlotLine({
        value: new Date(this.formatDatetimeToUTC(event.timestamp)).getTime(),
        color: "#0060fe",
        width: 2,
        id: "plot-line-" + event.id,
        label: {
          rotation: 0,
          style: {
            color: "#0060fe",
          },
          text: html,
          useHTML: true,
          y: -14,
          x: -11,
        },
      });
    });
  }

  get eventPlotLines(): any {
    const plotLines: any = [];
    this.infoEvents.forEach(event => {
      const html = `
          <i class="plot-line-label-icon mdi ${this.eventIcon(event.type)}"></i>
          <div class="plot-line-tooltip">
            <p><b>${this.convertTimestamp(event.timestamp)}</b></p>
            <p>${event.name}</p>
          </div>
        `;
      plotLines.push({
        value: new Date(this.formatDatetimeToUTC(event.timestamp)).getTime(),
        color: "#0060fe",
        width: 2,
        id: "plot-line-" + event.id,
        label: {
          rotation: 0,
          style: {
            color: "#0060fe",
          },
          text: html,
          useHTML: true,
          y: -14,
          x: -11,
        },
      });
    });
    return plotLines;
  }
}
















































































import { Component, PropSync, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
//  types
import { ExtendedVessel } from "@/types/Vessel";
import { HullCoating } from "@/types/HullCoating";

//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
import FoulingModule from "@/store/clients/Fouling.module";

const Fouling = getModule(FoulingModule, store);
const Vessels = getModule(VesselsModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component
export default class HullCoatingModal extends Vue {
  @PropSync("isOpen", { type: Boolean, default: false }) dialog!: boolean;
  @Prop() readonly longTrendName!: string;

  payload: HullCoating = { vesselId: this.vessel?.id } as HullCoating;
  manufacturers = ["AkzoNobel", "Jotun", "Hempel", "PPG", "BASF", "Nipsea Group", "Dow", "Valspar", "Chukogu Marine Paints", "Sherwin-Williams Company", "Axalta Coating Systems"];
  types = ["Antifouling", "Copper", "Silcon Based", "TBD"];

  // @Getters
  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get hullCoatingManufacturer(): any[] {
    return Fouling.hullCoatingManufacturer;
  }

  get hullCoatingType(): any[] {
    return Fouling.hullCoatingType;
  }

  get isSaveDisabled(): boolean {
    return !this.payload.manufacturerId || !this.payload.typeId || !this.payload.brand;
  }

  fieldRequired = {
    required: (value: any) => !!value || "Field is required",
  };

  // @Methods
  open(): void {
    this.dialog = true;
  }

  cancel(): void {
    this.dialog = false;
  }

  async submit(): Promise<void> {
    if (!this.vessel) return;
    try {
      await Fouling.postHullCoatingVessel(this.payload);
      this.payload = await Fouling.fetchHullCoatingVessel(this.vessel.id);
      this.dialog = false;
      Snackbar.showSnackbar({
        text: "Hull Coating successfully <b>saved</b>",
        color: "success",
      });
    } catch (err) {
      Snackbar.showSnackbar({ text: "Failed to save Hull Coating" });
    }
  }

  async created(): Promise<void> {
    await Fouling.fetchHullCoatingType();
    await Fouling.fetchHullCoatingManufacturer();
    if (this.vessel) {
      const response = await Fouling.fetchHullCoatingVessel(this.vessel.id);
      if (!response) {
        this.payload.manufacturerId = null;
        this.payload.typeId = null;
      } else this.payload = response;
    }
  }
}

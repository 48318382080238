import { render, staticRenderFns } from "./BenchmarkCard.vue?vue&type=template&id=bf3e2b28&scoped=true&"
import script from "./BenchmarkCard.vue?vue&type=script&lang=ts&"
export * from "./BenchmarkCard.vue?vue&type=script&lang=ts&"
import style0 from "./BenchmarkCard.vue?vue&type=style&index=0&id=bf3e2b28&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf3e2b28",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VIcon,VSkeletonLoader,VSpacer,VSystemBar,VTooltip})

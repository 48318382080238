

























































































import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
// types
import { SpeedLossHistory } from "@/types/SpeedLossHistory";
import { FoulingChartConfig } from "@/types/FoulingChartConfig";
//  components
import GraphCard from "@/components/Fouling/GraphCard.vue";
import BenchmarkCard from "@/components/Fouling/BenchmarkCard.vue";
import StatisticsCard from "@/components/Fouling/StatisticsCard.vue";
import LimitsCard from "@/components/Fouling/LimitsCard.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import FoulingModule from "@/store/clients/Fouling.module";

const Fouling = getModule(FoulingModule, store);

interface TrendPeriodMeta {
  vesselId: number;
  fromDate: string;
  toDate: string;
  benchmark: number;
  trendEndValue: number;
  trendLine: { a: number; b: number };
  speedLossHistory: SpeedLossHistory[];
}

@Component({
  components: {
    GraphCard,
    BenchmarkCard,
    StatisticsCard,
    LimitsCard,
    // ResultCard,
  },
})
export default class SpeedLossTrendDetailsModal extends Vue {
  dialog = false;
  loading = true;
  periodMeta: any = null;

  get hasNextPeriod(): boolean {
    return Boolean(this.periodMeta?.nextPeriodStartDate);
  }

  get hasPreviousPeriod(): boolean {
    return Boolean(this.periodMeta?.previousPeriodStartDate);
  }

  get getFoulingChartConfig(): FoulingChartConfig {
    return Fouling.foulingChartConfig;
  }

  get speedMeasurementSource(): string {
    return this.getFoulingChartConfig.useDerivedStw ? "Derived STW" : "Speed Log";
  }

  exportExcel(): void {
    // LongTrendExportClient.exportExcelPeriod(this.meta?.vesselId, this.meta?.longTrendId, this.meta?.startDate, this.meta?.endDate);
  }

  nextPeriod(): void {
    if (this.hasNextPeriod) this.$emit("onTrendPeriodChanged", this.periodMeta.nextPeriodStartDate);
  }

  previousPeriod(): void {
    if (this.hasPreviousPeriod) this.$emit("onTrendPeriodChanged", this.periodMeta.previousPeriodStartDate);
  }

  updatePeriod(data: TrendPeriodMeta): void {
    this.periodMeta = data;
  }

  open(data: TrendPeriodMeta): void {
    this.periodMeta = data;
    this.loading = false;
    this.dialog = true;
  }

  close(): void {
    this.dialog = false;
  }
}

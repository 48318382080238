









































import { Component, Prop, Vue } from "vue-property-decorator";
import { TrendPeriodMeta } from "@/types/TrendPeriodMeta";

@Component
export default class StatisticsCard extends Vue {
  @Prop({ default: true }) loading!: boolean;
  @Prop({ required: true }) readonly trendPeriodMeta!: TrendPeriodMeta;

  tooltip = "The number of long trend points shows the number of days where the conditions were suitable for a data point to be included.";

  get pointCount(): number | undefined {
    return this.trendPeriodMeta.speedLossHistory.length;
  }

  get trendFunction(): string | undefined {
    return `${this.trendPeriodMeta?.trendLine.a.toFixed(4)}x ${this.trendPeriodMeta?.trendLine.b.toFixed(4)}`;
  }

  round(value: number, precision = 1): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}

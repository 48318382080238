






























































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
//  types
import { FoulingChartConfig } from "@/types/FoulingChartConfig";
//  modules
import { getModule } from "vuex-module-decorators";
import FoulingModule from "@/store/clients/Fouling.module";

const Fouling = getModule(FoulingModule, store);

@Component
export default class LimitsCard extends Vue {
  @Prop({ default: true }) loading!: boolean;

  tooltip = "The on board data collection limits met for valid long trend points";

  limits = [
    {
      id: 1,
      exportedAt: "",
      minPower: 30,
      windSpeed: 6,
      minTime: null,
    },
    {
      id: 1,
      exportedAt: "2019-09-03",
      minPower: 30,
      windSpeed: 6,
      minTime: 3600,
    },
  ];

  round(value: number, precision = 1): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  get getFoulingChartConfig(): FoulingChartConfig {
    return Fouling.foulingChartConfig;
  }
}

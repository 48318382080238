















































































































import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";

// types
import { ExtendedVessel } from "@/types/Vessel";
import { VesselEvent } from "@/types/vesselEvent";
import { FoulingEventImpact } from "@/types/FoulingEventImpact";

//  modules
import { getModule } from "vuex-module-decorators";
import FoulingModule from "@/store/clients/Fouling.module";
import VesselsModule from "@/store/clients/Vessels.module";
import VesselEventsModule from "@/store/clients/VesselEvents.module";
import { FoulingChartConfig } from "@/types/FoulingChartConfig";

const Fouling = getModule(FoulingModule, store);
const Vessels = getModule(VesselsModule, store);
const VesselEvents = getModule(VesselEventsModule, store);

@Component({
  components: {},
})
export default class EventImpactModal extends Vue {
  @PropSync("isOpen", { type: Boolean, default: false }) dialog!: boolean;

  isLoading = true;
  fetchEventImpactError = false;
  eventImpacts: FoulingEventImpact[] = [];
  selectedTrendEvent: VesselEvent | undefined;
  tableHeaders = [
    { text: "MCR", value: "power" },
    { text: "Fuel Oil Impact", value: "fuelConsumptionDiff" },
  ];

  @Watch("trendEvents")
  @Watch("config")
  async trendEventsChanged(): Promise<void> {
    this.selectedTrendEvent = this.trendEvents[0];
    await this.fetchEventImpact();
  }

  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get hasTrendEvents(): boolean {
    return VesselEvents.trendEvents?.length > 0;
  }

  get trendEvents(): VesselEvent[] {
    return VesselEvents.trendEvents;
  }

  get config(): FoulingChartConfig {
    return Fouling.foulingChartConfig;
  }

  get hasEventImpacts(): boolean {
    return this.eventImpacts.length > 0;
  }

  open(): void {
    this.dialog = true;
  }

  close(): void {
    this.dialog = false;
  }

  formatDate(timestamp: string): string {
    return moment(timestamp).format("DD.MMM YYYY");
  }

  formatValue(value: number): string {
    return `${value.toFixed(2)} MT/day`;
  }

  async fetchEventImpact(): Promise<void> {
    try {
      this.isLoading = true;
      this.eventImpacts = await Fouling.fetchEventImpact({ vesselId: this.vessel!.id, eventDate: this.selectedTrendEvent!.timestamp });
      this.fetchEventImpactError = false;
    } catch (error) {
      this.fetchEventImpactError = true;
    } finally {
      this.isLoading = false;
    }
  }

  async created(): Promise<void> {
    if (!this.vessel || !this.hasTrendEvents) {
      this.isLoading = false;
    } else {
      this.selectedTrendEvent = this.trendEvents[0];
      await this.fetchEventImpact();
      this.isLoading = false;
    }
  }
}
















































































import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import store from "@/store";
//  types
import { ExtendedVessel } from "@/types/Vessel";
import { FoulingChartConfig } from "@/types/FoulingChartConfig";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
import FoulingModule from "@/store/clients/Fouling.module";

const Fouling = getModule(FoulingModule, store);
const Vessels = getModule(VesselsModule, store);
const Snackbar = getModule(SnackbarModule, store);

interface Payload {
  vesselId: number;
  mcr: number;
  windBeaufort: string;
}

@Component
export default class MCRModal extends Vue {
  @PropSync("isOpen", { type: Boolean, default: false }) dialog!: boolean;

  payload: Payload = { vesselId: this.vessel?.id } as Payload;
  foulingChartConfig: FoulingChartConfig = {} as FoulingChartConfig;
  windBeaufort = [
    { text: "BF3", value: 3 },
    { text: "BF4", value: 4 },
    { text: "BF5", value: 5 },
    { text: "BF6", value: 6 },
    { text: "BF7", value: 7 },
    { text: "BF8", value: 8 },
    { text: "BF9", value: 9 },
    { text: "BF10", value: 10 },
    { text: "BF11", value: 11 },
    { text: "BF12", value: 12 },
  ];

  // @Getters
  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get getFoulingChartConfig(): FoulingChartConfig {
    return Fouling.foulingChartConfig;
  }

  get isSaveDisabled(): boolean {
    return !this.foulingChartConfig.minPercentMcr || !this.foulingChartConfig.maxBeaufortWind;
  }

  fieldRequired = {
    required: (value: any) => !!value || "Field is required",
    mcr: (value: any) => {
      if (!!!value) return "Field is required";
      else return (1 <= value && value <= 100) || "MCR must be a number between 1 and 100.";
    },
  };

  numericFilter(event: any): void {
    event = event ? event : window.event;
    const expect = event.target.value.toString() + event.key.toString();
    return !/^\d+$/.test(expect) ? event.preventDefault() : false;
  }

  cloneConfig(): void {
    this.foulingChartConfig = JSON.parse(JSON.stringify(this.getFoulingChartConfig));
  }

  open(): void {
    this.cloneConfig();
    this.dialog = true;
  }

  cancel(): void {
    this.dialog = false;
    this.cloneConfig();
  }

  async updateFoulingChartConfig(): Promise<void> {
    try {
      await Fouling.updateFoulingChartConfig(this.foulingChartConfig);
      await Fouling.fetchFoulingChartConfig(this.vessel!.id);
      this.$emit("filteringUpdated");
      Snackbar.showSnackbar({ text: "Filter settings successfully updated", color: "success" });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to update Filter settings" });
    } finally {
      this.dialog = false;
    }
  }

  async created(): Promise<void> {
    if (!this.vessel) return;
    this.foulingChartConfig = await Fouling.fetchFoulingChartConfig(this.vessel.id);
  }
}

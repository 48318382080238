






















































import { Component, Prop, Vue } from "vue-property-decorator";
import { TrendPeriodMeta } from "@/types/TrendPeriodMeta";

@Component
export default class BenchmarkCard extends Vue {
  @Prop({ default: true }) loading!: boolean;
  @Prop({ required: true }) readonly trendPeriodMeta!: TrendPeriodMeta;

  tooltip =
    "The Baseline is the value, derived from the design or shop-test data, which shows the original expected performance of the vessel. It is used as the zero level when displaying actual performance. The Benchmark Period is the period, immediately after a trend event, during which data is collected to provide an initial performance level so that any subsequent changes in performance can be evaluated.";

  get greenZoneEnds(): number {
    return this.trendPeriodMeta.benchmark.level - 5;
  }

  get yellowZoneEnds(): number {
    return this.greenZoneEnds - 5;
  }

  round(value: number, precision = 1): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}

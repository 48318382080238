
























































































































import { Component, Vue, Prop } from "vue-property-decorator";
import dateHelper from "@/Scripts/utilities/date-helper";
//  types
import { SpeedLossStatistic } from "@/types/SpeedLossStatistic";
import { VesselEvent } from "@/types/vesselEvent";
import { FoulingAddedConsumption } from "@/types/FoulingAddedConsumption";

@Component({})
export default class SpeedLossCard extends Vue {
  @Prop({ required: true }) readonly latestSpeedLossStatistics!: SpeedLossStatistic | null;
  @Prop({ required: true }) readonly latestTrendEvent!: VesselEvent;
  @Prop({ required: true }) readonly addedFuelConsumption!: FoulingAddedConsumption;
  @Prop() readonly isBenchmarking!: boolean;

  // @Getters
  get latestSpeedLossPercentage(): number | string {
    if (this.latestSpeedLossStatistics == null) return "N/A";
    return Math.abs(this.latestSpeedLossStatistics.trendEndValue * -1).toFixed(1);
  }

  get benchmarkLevel(): number | string {
    if (this.latestSpeedLossStatistics == null) return "N/A";
    return this.latestSpeedLossStatistics.benchmark.level.toFixed(1);
  }

  get isLoss(): boolean {
    if (!this.latestSpeedLossStatistics) return true;
    return this.latestSpeedLossStatistics.trendEndValue <= 0;
  }

  get greenZoneEnds(): number {
    if (!this.latestSpeedLossStatistics) return 0 - 5;
    return this.latestSpeedLossStatistics.benchmark.level - 5;
  }

  get yellowZoneEnds(): number {
    return this.greenZoneEnds - 5;
  }

  get state(): string {
    if (this.isBenchmarking) return "benchmarking";
    if (this.latestSpeedLossStatistics == null) return "N/A";
    if (this.latestSpeedLossStatistics.trendEndValue < this.yellowZoneEnds) return "high";
    if (this.latestSpeedLossStatistics.trendEndValue < this.greenZoneEnds && this.latestSpeedLossStatistics.trendEndValue > this.yellowZoneEnds) return "medium";
    if (this.latestSpeedLossStatistics.trendEndValue > this.greenZoneEnds) return "normal";
    return "N/A";
  }

  get statecolor(): string {
    switch (this.state) {
      case "high":
        return "#F04438";
      case "medium":
        return "#FDB022";
      case "normal":
        return "#17B26A";
      case "benchmarking":
        return "#2970FF";
      default:
        return "#fff";
    }
  }

  get stateicon(): string {
    switch (this.state) {
      case "high":
        return "mdi-alert-circle";
      case "medium":
        return "mdi-alert-circle";
      case "normal":
        return "mdi-check-circle";
      case "benchmarking":
        return "mdi-gauge";
      default:
        return "mdi-check-circle";
    }
  }

  get baselineTooltipText(): string {
    return "Vessel's 0% performance, speed loss value is calculated in relation to it.";
  }

  get benchmarkTooltipText(): string {
    return "Vessel performance during recent trend event, determines its status as green, yellow, or red.";
  }

  get cumulativeAddedConsumptionTooltipText(): string {
    return "Total extra fuel consumed due to speed loss during the latest trend period.";
  }

  get classes(): any[] {
    return ["speed-loss", this.state];
  }   

  get benchmarkInfo(): string | undefined {
    if (this.latestSpeedLossStatistics?.benchmark.isManual) {
      return `${this.benchmarkLevel}% from baseline`;
    }
    return this.latestTrendEvent.timestamp 
      ? `${dateHelper.getFormatedDateString(this.latestTrendEvent.timestamp)} ${this.latestTrendEvent.name}`
      : this.latestTrendEvent.name;
  }

  get cumulativeAddedFuelConsumption(): string | undefined {
    if (!this.addedFuelConsumption || !this.latestTrendEvent || this.isBenchmarking) return;
    if (this.addedFuelConsumption.fromDate !== this.latestTrendEvent.timestamp) {
      return "N/A";
    }
    const roundedNumber = Math.round(Number(this.addedFuelConsumption.cumulativeAddedConsumption));
    return roundedNumber.toLocaleString();
  }

  formatColorLegendValue(value: number): string {
    // Showing "opposite" sign because "speed loss" already indicates a negative number.
    const adjustedValue = -value;
    return adjustedValue.toFixed(1);
  }

  round(value: number, precision = 1): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}








































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
// utilities
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import moment from "moment";
import _ from "underscore";
//  types
import { ExtendedVessel } from "@/types/Vessel";
import { SpeedLossStatistic } from "@/types/SpeedLossStatistic";

import { getModule } from "vuex-module-decorators";
import FoulingModule from "@/store/clients/Fouling.module";
import VesselsModule from "@/store/clients/Vessels.module";
import { FoulingChartConfig } from "@/types/FoulingChartConfig";

const Fouling = getModule(FoulingModule, store);
const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    Highcharts: Chart,
  },
})
export default class PropulsionEfficencyCard extends Vue {
  chart!: any;
  chartLoaded = false;
  isFirstPeriodFromDateModalActive = false;
  isFirstPeriodToDateModalActive = false;
  isSecondPeriodFromDateModalActive = false;
  isSecondPeriodToDateModalActive = false;
  isFirstPeriodFromDateModalExpandedActive = false;
  isFirstPeriodToDateModalExpandedActive = false;
  isSecondPeriodFromDateModalExpandedActive = false;
  isSecondPeriodToDateModalExpandedActive = false;
  isToDateModalActive = false;
  isDataLoading = false;
  filterMenu = false;
  filterMenuModal = false;
  expandChart = false;
  speed = [this.foulingConfig.peMinSpeed, this.foulingConfig.peMaxSpeed];
  granularity = this.foulingConfig.peGranularity;
  condition = this.foulingConfig.peCondition;
  currentDate: string = moment.utc().format("YYYY-MM-DD");
  firstPeriodFromDate: string = moment.utc(this.foulingConfig.pePeriodOneFromDate).format("YYYY-MM-DD");
  firstPeriodToDate: string = moment.utc(this.foulingConfig.pePeriodOneToDate).format("YYYY-MM-DD");
  firstPeriodData: number[][] = [];
  filtredFirstPeriodData: number[][] = [];
  secondPeriodFromDate: string = moment.utc(this.foulingConfig.pePeriodTwoFromDate).format("YYYY-MM-DD");
  secondPeriodToDate: string = moment.utc(this.foulingConfig.pePeriodTwoToDate).format("YYYY-MM-DD");
  secondPeriodData: number[][] = [];
  filtredSecondPeriodData: number[][] = [];
  conditionsList: { text: string }[] = [
    {
      text: "Laden",
    },
    {
      text: "Ballast",
    },
  ];
  intervalList: { text: string; value: string }[] = [
    {
      text: "Auto",
      value: "Auto",
    },
    {
      text: "Day",
      value: "Day",
    },
    {
      text: "Hour",
      value: "Hour",
    },
    {
      text: "Quarter (Every 15 minutes)",
      value: "QuarterHour",
    },
    {
      text: "Minute",
      value: "Minute",
    },
    {
      text: "Raw",
      value: "Raw",
    },
  ];
  intervalLimit: { [key: string]: number } = {
    Raw: this.daysToMinutes(1),
    Minute: this.daysToMinutes(2),
    QuarterHour: this.daysToMinutes(14),
    Hour: this.daysToMinutes(90),
  };
  speedRange: { text: string } = {
    text: "Speed Range",
  };

  // @Getters

  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get vesselLogDataMinDate(): string {
    if (!Vessels.currentVessel) return "2000-01-01";
    return Vessels.currentVessel.logDataMinDate;
  }

  get hasDraftConditionRanges(): boolean {
    if ((this.vessel?.ballastDraftHigh || this.vessel?.ballastDraftLow || this.vessel?.ladenDraftHigh || this.vessel?.ladenDraftLow) == null) return false;
    else return true;
  }

  get autoGranularity(): string {
    const firstPeriodSelectedTimeInMinutes = this.minutesDiff(new Date(this.firstPeriodFromDate), new Date(this.firstPeriodToDate));
    const secondPeriodSelectedTimeInMinutes = this.minutesDiff(new Date(this.secondPeriodFromDate), new Date(this.secondPeriodToDate));
    const selectedTimeInMinutes = Math.max(firstPeriodSelectedTimeInMinutes, secondPeriodSelectedTimeInMinutes);
    if (selectedTimeInMinutes <= 1440) {
      return "Raw";
    } else if (selectedTimeInMinutes <= 2880) {
      return "Minute";
    } else if (selectedTimeInMinutes <= 20160) {
      return "QuarterHour";
    } else if (selectedTimeInMinutes <= 129600) {
      return "Hour";
    }

    return "Day";
  }

  get averageKPI(): string {
    if (!this.filtredFirstPeriodData.length || !this.filtredSecondPeriodData.length) return "";
    const secondPeriodMap = new Map();
    for (const [speed, power] of this.filtredSecondPeriodData) {
      const roundedSpeed = Math.round(speed);
      secondPeriodMap.set(roundedSpeed, power);
    }
    const diffs = [];
    for (const [speed, power1] of this.filtredFirstPeriodData) {
      const roundedSpeed1 = Math.round(speed);
      if (secondPeriodMap.has(roundedSpeed1)) {
        const power2 = secondPeriodMap.get(roundedSpeed1);
        const diff = power2 / power1;
        diffs.push(diff);
      }
    }
    if (diffs.length === 0) return "";
    const averageDiff = diffs.reduce((sum, value) => sum + value, 0) / diffs.length;
    const averageDiffPercentage = (averageDiff - 1) * 100;
    return averageDiffPercentage.toFixed(1);
  }

  get foulingConfig(): FoulingChartConfig {
    return Fouling.foulingChartConfig;
  }

  get stwLegend(): string {
    return this.foulingConfig.useDerivedStw ? "Derived STW" : "Speed Log";
  }

  get ChartOptions(): any {
    if (!this.chartLoaded || !Highcharts) return {};
    const ctx = this;
    const options = {
      chart: ctx.chartSettings,
      title: { text: "" },
      legend: {
        // enabled: false,
      },
      yAxis: {
        min: 0,
        max: 30000,
        title: {
          text: "Shaft Power",
          style: { color: "#331714" },
        },
        labels: {
          format: "{value} kW",
          style: { color: "#331714" },
        },
      },
      xAxis: {
        min: 0,
        max: 25,
        labels: {
          format: "{value} knots",
          style: { color: "#331714" },
        },
        title: {
          text: this.stwLegend,
          style: { color: "#331714" },
        },
      },
      plotOptions: {
        area: {
          threshold: 0,
          marker: {
            enabled: false,
          },
        },
        series: {
          showInLegend: true,
          dataLabels: { enabled: false },
        },
      },
      tooltip: {
        formatter: function () {
          const $this: any = this;
          const dateSpan = $this.point.date ? `<span>Date: <b>${moment.utc($this.point.date).format("DD. MMM, YYYY HH:mm")}</b></span><br>` : "";
          return `${dateSpan}<span>Shaft Power: <b>${Number($this.point.y.toFixed(2))} kW</b></span><br><span>STW: <b style="margin-bottom: 5px;">${$this.point.x.toFixed(1)} knots</b></span>`;
        },
      },
      series: this.seriesData,
      credits: { enabled: false },
      exporting: { enabled: false },
    };

    return options;
  }

  get chartSettings(): any {
    if (!this.chartLoaded) return {};
    const ctx = this;
    return {
      zoomType: "x",
      spacingTop: 30,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      style: { fontFamily: "Helvetica Neue" },
      events: {
        selection: function (event: any) {
          //  trigger onChartZoom event on zoom
          ctx.$emit("onEventChartZoom", event);
        },
      },
    };
  }

  get theoreticalCurve(): number[][] {
    if (_.isEmpty(Fouling.propulsionCurve)) return [];
    const xValues = Fouling.propulsionCurve.xValues;
    const yValues = Fouling.propulsionCurve.yValues;

    const series: number[][] = [];

    yValues.forEach((v, i) => series.push([xValues[i], v]));

    return series;
  }

  get benchmarkCurve(): number[][] {
    if (_.isEmpty(Fouling.propulsionCurve) || this.latestSpeedLossStatistics == null || this.latestSpeedLossStatistics.benchmark.level === 0) return [];
    const xValues = Fouling.propulsionCurve.xValues;
    const yValues = Fouling.propulsionCurve.yValues;

    const series: number[][] = [];
    const benchmarkPercentFactor = 1 + this.latestSpeedLossStatistics!.benchmark.level / 100;
    yValues.forEach((v, i) => series.push([xValues[i] * benchmarkPercentFactor, v]));

    return series;
  }

  get latestSpeedLossStatistics(): SpeedLossStatistic | null {
    return Fouling.speedLossStatistics[Fouling.speedLossStatistics.length - 1] ?? null;
  }

  get seriesData(): any {
    // bugfix < >
    if (this.chart.series.length) {
      /* IMPORTANT: There is also a bug in Highcharts when it has more than 2 series and they are updated dynamically it "loses" correct indexes of the series and displays data in a weird manner so the solution is to clear the series array before it will be updated */
      while (this.chart.series.length) {
        this.chart.series[0].remove();
      }
    }
    // bugfix </>
    const scatterPointsSerie = {
      name: "Period #1",
      type: "scatter",
      keys: ["x", "y", "date"],
      data: this.filtredFirstPeriodData,
      stickyTracking: false,
      /* IMPORTANT:  https://www.highcharts.com/forum/viewtopic.php?f=9&t=44589 Turns out scatter points have a bug. When they get updated they can be displayed as line-through-dots with property lineWidth: 2 so here we need to set the lineWidth: 0, otherwise it should be set always through chart.series.forEach loop in serie.options.lineWidth*/
      lineWidth: 0,
      color: "#0600FE",
      marker: {
        radius: 2.5,
        symbol: "circle",
        color: "#0600FE",
      },
    };

    const scatterPointsSerie2 = {
      name: "Period #2",
      type: "scatter",
      keys: ["x", "y", "date"],
      data: this.filtredSecondPeriodData,
      stickyTracking: false,
      color: "orange",
      /* IMPORTANT: https://www.highcharts.com/forum/viewtopic.php?f=9&t=44589 Turns out scatter points have a bug. When they get updated they can be displayed as line-through-dots with property lineWidth: 2 so here we need to set the lineWidth: 0, otherwise it should be set always through chart.series.forEach loop in serie.options.lineWidth */
      lineWidth: 0,
      marker: {
        radius: 2.5,
        symbol: "circle",
        color: "orange",
      },
    };

    const benchmarkLine = {
      name: "Benchmark Curve",
      type: "spline",
      data: this.benchmarkCurve,
      stickyTracking: false,
      lineWidth: 2,
      color: "#0de40d",
      marker: {
        enabled: false,
        radius: 2.5,
        symbol: "circle",
        color: "#0de40d",
      },
    };

    const theoreticalLine = {
      name: "Theoretical Curve",
      type: "spline",
      data: this.theoreticalCurve,
      stickyTracking: false,
      lineWidth: 2,
      dashStyle: "ShortDot",
      color: "#0de40d",
      marker: {
        enabled: false,
        radius: 2.5,
        symbol: "circle",
        color: "#0de40d",
      },
    };

    const series = [scatterPointsSerie, scatterPointsSerie2, benchmarkLine, theoreticalLine];

    return series;
  }

  get propulsionEfficiencyToolTooltipText(): string {
    return "Compares speed and power between two periods, helping verify if increased power requirements align with observed speed loss, and shows how each period compares to the Benchmark and Baseline.";
  }

  get avgChangeShaftPowerTooltipText(): string {
    return "For instance, if the average shaft power in Period 2 is 10,000 kW and in Period 1 is 8,000 kW, the average change will be 25%.";
  }

  get isNautilusVessel(): boolean {
    return this.vessel?.onboardSystem === "Nautilus";
  }

  //  @Methods
  chartReady(chart: any): void {
    this.chart = chart;
    this.chart.update(this.ChartOptions, true);
    this.chartLoaded = true;
  }

  daysToMinutes(days: number): number {
    return days * 24 * 60;
  }

  isDataIntervalAllowedForDataSize(value: string): boolean {
    if (!this.intervalLimit[value]) return true;
    return (
      moment.duration(moment.utc(this.firstPeriodToDate).diff(this.firstPeriodFromDate)).asMinutes() <= this.intervalLimit[value] &&
      moment.duration(moment.utc(this.secondPeriodToDate).diff(this.secondPeriodFromDate)).asMinutes() <= this.intervalLimit[value]
    );
  }

  async onPeriodDateChanged(datepickerRef: string, date: string): Promise<void> {
    (this.$refs[datepickerRef] as any).save(date);
    this.isDataLoading = true;
    this.foulingConfig.pePeriodOneFromDate = this.firstPeriodFromDate;
    this.foulingConfig.pePeriodOneToDate = this.firstPeriodToDate;
    this.foulingConfig.pePeriodTwoFromDate = this.secondPeriodFromDate;
    this.foulingConfig.pePeriodTwoToDate = this.secondPeriodToDate;

    try {
      await Fouling.updateFoulingChartConfig(this.foulingConfig);

      if (
        datepickerRef === "menuFirstPeriodFromDate" ||
        datepickerRef === "menuFirstPeriodToDate" ||
        datepickerRef === "menuFirstPeriodFromDateExpanded" ||
        datepickerRef === "menuFirstPeriodToDateExpanded"
      ) {
        const firstPeriodResponse = await Fouling.fetchPropulsionEfficency({
          vesselId: this.vessel!.id,
          fromDate: this.firstPeriodFromDate,
          toDate: this.firstPeriodToDate,
          condition: this.foulingConfig.peCondition,
          granularity: this.foulingConfig.peGranularity === "Auto" ? this.autoGranularity : this.foulingConfig.peGranularity,
        });
        this.firstPeriodData = Object.keys(firstPeriodResponse).map(key => {
          return [...firstPeriodResponse[key].split(",").map((item: string) => Number(item.replace(/\(|\)/g, ""))), key];
        });
        this.filtredFirstPeriodData = this.firstPeriodData;
      } else if (
        datepickerRef === "menuSecondPeriodFromDate" ||
        datepickerRef === "menuSecondPeriodToDate" ||
        datepickerRef === "menuSecondPeriodFromDateExpanded" ||
        datepickerRef === "menuSecondPeriodToDateExpanded"
      ) {
        const secondPeriodResponse = await Fouling.fetchPropulsionEfficency({
          vesselId: this.vessel!.id,
          fromDate: this.secondPeriodFromDate,
          toDate: this.secondPeriodToDate,
          condition: this.foulingConfig.peCondition,
          granularity: this.foulingConfig.peGranularity === "Auto" ? this.autoGranularity : this.foulingConfig.peGranularity,
        });
        this.secondPeriodData = Object.keys(secondPeriodResponse).map(key => {
          return [...secondPeriodResponse[key].split(",").map((item: string) => Number(item.replace(/\(|\)/g, ""))), key];
        });
        this.filtredSecondPeriodData = this.secondPeriodData;
      }
      await Fouling.fetchPropulsionEfficencyCurve({ vesselId: this.vessel!.id, condition: this.foulingConfig.peCondition });
      this.applySpeedFilter();
    } finally {
      this.isDataLoading = false;
    }
  }

  async applyFilter(): Promise<void> {
    this.filterMenu = false;
    this.filterMenuModal = false;
    this.foulingConfig.peMinSpeed = this.speed[0];
    this.foulingConfig.peMaxSpeed = this.speed[1];
    if (this.foulingConfig.peCondition !== this.condition || this.foulingConfig.peGranularity !== this.granularity) {
      this.foulingConfig.peGranularity = this.granularity;
      this.foulingConfig.peCondition = this.condition;
      await this.fetchData();
    } else {
      this.applySpeedFilter();
    }
    await Fouling.updateFoulingChartConfig(this.foulingConfig);
  }

  applySpeedFilter(): void {
    this.filtredFirstPeriodData = this.firstPeriodData.filter(item => item[0] >= this.foulingConfig.peMinSpeed && item[0] <= this.foulingConfig.peMaxSpeed);
    this.filtredSecondPeriodData = this.secondPeriodData.filter(item => item[0] >= this.foulingConfig.peMinSpeed && item[0] <= this.foulingConfig.peMaxSpeed);
  }

  async fetchData(): Promise<void> {
    if (!this.vessel || this.isDataLoading) return;
    this.isDataLoading = true;
    try {
      const firstPeriodResponse = await Fouling.fetchPropulsionEfficency({
        vesselId: this.vessel.id,
        fromDate: this.firstPeriodFromDate,
        toDate: this.firstPeriodToDate,
        condition: this.foulingConfig.peCondition,
        granularity: this.foulingConfig.peGranularity === "Auto" ? this.autoGranularity : this.foulingConfig.peGranularity,
      });
      this.firstPeriodData = Object.keys(firstPeriodResponse).map(key => {
        return [...firstPeriodResponse[key].split(",").map((item: string) => Number(item.replace(/\(|\)/g, ""))), key];
      });
      this.filtredFirstPeriodData = this.firstPeriodData;
      const secondPeriodResponse = await Fouling.fetchPropulsionEfficency({
        vesselId: this.vessel.id,
        fromDate: this.secondPeriodFromDate,
        toDate: this.secondPeriodToDate,
        condition: this.foulingConfig.peCondition,
        granularity: this.foulingConfig.peGranularity === "Auto" ? this.autoGranularity : this.foulingConfig.peGranularity,
      });
      this.secondPeriodData = Object.keys(secondPeriodResponse).map(key => {
        return [...secondPeriodResponse[key].split(",").map((item: string) => Number(item.replace(/\(|\)/g, ""))), key];
      });
      this.filtredSecondPeriodData = this.secondPeriodData;
      this.applySpeedFilter();
      await Fouling.fetchPropulsionEfficencyCurve({ vesselId: this.vessel.id, condition: this.foulingConfig.peCondition });
    } finally {
      this.isDataLoading = false;
    }
  }

  expandWidget(): void {
    this.expandChart = true;
  }

  minutesDiff(fromDate: Date, toDate: Date): number {
    const diff = (fromDate.getTime() - toDate.getTime()) / 1000 / 60;

    return Math.abs(Math.round(diff));
  }

  closeFilterMenu(): void {
    this.filterMenu = false;
    this.filterMenuModal = false;
    this.speed = [this.foulingConfig.peMinSpeed, this.foulingConfig.peMaxSpeed];
    this.granularity = this.foulingConfig.peGranularity;
    this.condition = this.foulingConfig.peCondition;
  }

  async created(): Promise<void> {
    await this.fetchData();
    this.$parent.$on("refetchPropulsionEfficiencyData", this.fetchData);
  }
}

















































































































































































































































































































































































































































import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
//  types
import { ExtendedVessel } from "@/types/Vessel";
import { FoulingChartConfig } from "@/types/FoulingChartConfig";
import { MapMarker } from "@/types/mapMarker";
import { SpeedLossHistory } from "@/types/SpeedLossHistory";
import { SpeedLossStatistic } from "@/types/SpeedLossStatistic";
import { TrendPeriodMeta } from "@/types/TrendPeriodMeta";
import { FoulingAddedConsumption } from "@/types/FoulingAddedConsumption";
import { VesselEvent } from "@/types/vesselEvent";

//  components
import FoulingContentLoader from "@/components/Fouling/FoulingContentLoader.vue";
import FeatureNotAvailable from "@/components/FeatureNotAvailable.vue";
import EditVesselEvent from "@/components/EditVesselEvent.vue";
import EventImpactModal from "@/components/Fouling/modals/EventImpactModal.vue";
import BenchmarkSettingsModal from "@/components/Fouling/modals/BenchmarkSettingsModal.vue";
import MCRModal from "@/components/Fouling/modals/MCRModal.vue";
import HullCoatingModal from "@/components/Fouling/modals/HullCoatingModal.vue";
import SpeedLossCard from "@/components/Fouling/SpeedLossCard.vue";
import SpeedLossChartCard from "@/components/Fouling/SpeedLossChartCard.vue";
import DiagnosticCard from "@/components/DiagnosticCard.vue";
import PropulsionEfficencyCard from "@/components/Fouling/PropulsionEfficencyCard.vue";
import OperationalProfileCard from "@/components/Fouling/OperationalProfileCard.vue";
import DiagnosticsDetails from "@/components/diagnosticsDetails/DiagnosticsDetails.vue";
import SpeedLossTrendDetailsModal from "@/components/Fouling/modals/SpeedLossTrendDetailsModal.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

//  modules
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/clients/Snackbar.module";
import VesselsModule from "@/store/clients/Vessels.module";
import VesselEventsModule from "@/store/clients/VesselEvents.module";
import FoulingModule from "@/store/clients/Fouling.module";

const Snackbar = getModule(SnackbarModule, store);
const Vessels = getModule(VesselsModule, store);
const VesselEvents = getModule(VesselEventsModule, store);
const Fouling = getModule(FoulingModule, store);

@Component({
  components: {
    FoulingContentLoader,
    FeatureNotAvailable,
    SpeedLossCard,
    SpeedLossChartCard,
    DiagnosticCard,
    PropulsionEfficencyCard,
    OperationalProfileCard,
    EditVesselEvent,
    EventImpactModal,
    BenchmarkSettingsModal,
    MCRModal,
    ConfirmDialog,
    HullCoatingModal,
    DiagnosticsDetails,
    SpeedLossTrendDetailsModal,
  },
})
export default class FoulingView extends Vue {
  @Ref("CreateEventModal") CreateEventModal!: any;
  @Ref("EventImpactModal") EventImpactModal!: any;
  @Ref("BenchmarkSettingsModal") BenchmarkSettingsModal!: any;
  @Ref("MCRModal") MCRModal!: any;
  @Ref("HullCoatingModal") HullCoatingModal!: any;
  @Ref("SpeedLossTrendDetailsModal") SpeedLossTrendDetailsModal!: any;
  @Ref("ShipSpeedLogCard") DiagnosticCard!: any;
  @Ref("SpeedLossChartCard") SpeedLossChartCard!: any;
  @Ref("OperationalProfileCard") OperationalProfileCard!: any;
  @Ref("OperationalProfileExpanded") OperationalProfileExpanded!: any;
  @Ref("confirmDelete") confirmDelete!: any;

  loader = true;
  CreateEventModalActive = false;
  EventImpactModalActive = false;
  BenchmarkSettingsModalActive = false;
  MCRModalActive = false;
  HullCoatingModalActive = false;
  OperationalProfileModal = false;
  speedMeasurementModal = false;
  manageEventsModal = false;
  isDataLoading = false;
  tempUseDerivedStw = false;
  addedFuelConsumptions: FoulingAddedConsumption[] | null = null;
  foulingChartConfig: FoulingChartConfig = {} as FoulingChartConfig;
  operationalProfileDateRange: string[] = [];
  speedMeasurementItems: { text: string; value: boolean }[] = [
    {
      text: "Speed Log",
      value: false,
    },
    {
      text: "Derived STW",
      value: true,
    },
  ];
  eventTableHeaders = [
    { text: "Date", align: "start", sortable: false, value: "date" },
    { text: "Name", align: "start", sortable: false, value: "name" },
    { text: "Type", align: "start", sortable: false, value: "type" },
    { text: "Origin", align: "start", sortable: false, value: "origin" },
    { text: "Actions", align: "start", sortable: false, value: "actions" },
  ];

  // @Watchers
  @Watch("getFoulingChartConfig", { deep: true })
  onFoulingChartConfigChanged(): void {
    this.foulingChartConfig = JSON.parse(JSON.stringify(this.getFoulingChartConfig));
    this.operationalProfileDateRange = [this.getFoulingChartConfig.opFromDate, this.getFoulingChartConfig.opToDate];
  }

  //  @Getters
  get featureEnabled(): boolean {
    if (!this.vessel) return false;

    return this.vessel.features.some(feature => feature.name === "Fouling");
  }

  get isDerivedStwFeatureEnabled(): boolean {
    if (!this.vessel) return false;

    return this.vessel.features.some(feature => feature.name === "DerivedSpeedThroughWater");
  }

  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get speedLossHistory(): SpeedLossHistory[] {
    return Fouling.speedLossHistory;
  }

  get speedLossStatistics(): SpeedLossStatistic[] {
    return Fouling.speedLossStatistics;
  }

  get latestSpeedLossStatistics(): SpeedLossStatistic | null {
    return this.speedLossStatistics[this.speedLossStatistics.length - 1] ?? null;
  }

  get latestAddedConsumption(): FoulingAddedConsumption | undefined {
    if (!this.addedFuelConsumptions) return;
    const filteredFuelConsumptions = this.addedFuelConsumptions.filter(
      c => c.usingDerivedStw === this.foulingChartConfig.useDerivedStw && c.benchmarkLevel === this.latestSpeedLossStatistics?.benchmark.level
    );
    return filteredFuelConsumptions[filteredFuelConsumptions.length - 1];
  }

  get latestTrendEvent(): VesselEvent {
    return VesselEvents.trendEvents[0];
  }

  get latestSpeedLossHistory(): SpeedLossHistory[] {
    if (!this.latestSpeedLossStatistics) return [];
    return this.speedLossHistory.filter(
      item =>
        moment.utc(item.timestamp).valueOf() >= moment.utc(this.latestSpeedLossStatistics?.fromDate).valueOf() &&
        moment.utc(item.timestamp).valueOf() <= moment.utc(this.latestSpeedLossStatistics?.toDate).valueOf()
    );
  }

  get isBenchmarking(): boolean {
    if (!this.foulingChartConfig) return false;
    return !this.latestSpeedLossStatistics?.benchmark.end && !this.foulingChartConfig.useManualBenchmark;
  }

  get getFoulingChartConfig(): FoulingChartConfig {
    return Fouling.foulingChartConfig;
  }

  get benchmarkType(): { tooltip: string; icon: string } {
    if (this.foulingChartConfig.useManualBenchmark) {
      return {
        tooltip: `Using manual benchmark level: ${this.foulingChartConfig.benchmark}%`,
        icon: "mdi-alpha-m-circle",
      };
    } else {
      return {
        tooltip: "Using automatic calculated benchmark level",
        icon: "mdi-alpha-a-circle",
      };
    }
  }

  get derivedStwHint(): string {
    return this.tempUseDerivedStw
      ? "The derived Speed Through Water (STW) is calculated from the Speed Over Ground (SOG) and the ocean current’s speed and direction by resolving them into their components."
      : "";
  }

  get eventTableRows(): any[] {
    const tableRows: any[] = [];

    this.events.forEach(event => {
      tableRows.push({
        event,
        date: event.timestamp,
        name: event.name,
        type: event.type,
        origin: event.source,
        actions: ["edit", "delete"],
      });
    });

    return tableRows;
  }

  get events(): VesselEvent[] {
    return VesselEvents.allEvents;
  }

  //  @Methods
  async fetchData(): Promise<void> {
    if (!this.vessel) return;

    try {
      await Promise.all([
        Fouling.fetchSpeedLossStatistics(this.vessel.id),
        Fouling.fetchSpeedLossHistory({ vesselId: this.vessel.id }),
        VesselEvents.fetchEvents(this.vessel.id),
        VesselEvents.updateTrendEventTypes(),
      ]);
      this.foulingChartConfig = await Fouling.fetchFoulingChartConfig(this.vessel.id);
      this.addedFuelConsumptions = await Fouling.fetchAddedFuelConsumption(this.vessel.id);
      this.tempUseDerivedStw = this.foulingChartConfig.useDerivedStw;
    } catch (e) {
      Snackbar.showSnackbar({ text: "Failed to load data" });
    } finally {
      this.loader = false;
    }
  }

  async onFilteringSettingsUpdated(): Promise<void> {
    if (!this.vessel) return;
    this.isDataLoading = true;
    try {
      await Fouling.fetchSpeedLossHistory({ vesselId: this.vessel.id });
      await Fouling.fetchSpeedLossStatistics(this.vessel.id);
      this.addedFuelConsumptions = await Fouling.fetchAddedFuelConsumption(this.vessel.id);
      this.$emit("refetchPropulsionEfficiencyData");
    } catch (e) {
      Snackbar.showSnackbar({ text: "Failed to update data" });
    } finally {
      this.isDataLoading = false;
    }
  }

  openSpeedLossTrendDetailsModal(data: any): void {
    if (!data.vesselId) return;
    this.SpeedLossTrendDetailsModal.open(data);
  }

  async createEvent(): Promise<void> {
    const success = await this.CreateEventModal.open();
    if (success) {
      Snackbar.showSnackbar({ text: "Event created successfully", color: "success" });
      await this.fetchData();
    } else if (success === false) {
      Snackbar.showSnackbar({ text: "Failed to create event" });
    }
  }

  async editEvent(vesselEvent: VesselEvent): Promise<void> {
    const success = await this.CreateEventModal.open(vesselEvent);
    if (success) {
      Snackbar.showSnackbar({ text: "Event edited successfully", color: "success" });
    } else if (success === false) {
      Snackbar.showSnackbar({ text: "Failed to edit event" });
    }
  }

  async deleteEvent(vesselEvent: VesselEvent): Promise<void> {
    const confirmed = await this.confirmDelete.open(`Delete ${vesselEvent.name}`, "Are you sure you want to delete this event?");
    if (confirmed) {
      await VesselEvents.deleteEvent(vesselEvent);
      Snackbar.showSnackbar({ text: "Event deleted successfully", color: "success" });
    }
  }

  async onUseDerivedStwChanged(): Promise<void> {
    this.speedMeasurementModal = false;
    this.foulingChartConfig.useDerivedStw = this.tempUseDerivedStw;
    await Fouling.updateFoulingChartConfig(this.foulingChartConfig);
    await Fouling.fetchFoulingChartConfig(this.vessel!.id);
    await this.onFilteringSettingsUpdated();
  }

  closeSpeedMeasurementSettingsModal(): void {
    this.speedMeasurementModal = false;
    this.tempUseDerivedStw = this.foulingChartConfig.useDerivedStw;
  }

  closeManageEventsModal(): void {
    this.manageEventsModal = false;
  }

  closeOperationalProfileModal(): void {
    this.OperationalProfileModal = false;
  }

  onExpandMap(): void {
    this.OperationalProfileModal = true;
  }

  setEventImpact(): void {
    this.EventImpactModal.open();
  }

  async setBenchmarkSettings(): Promise<void> {
    await this.BenchmarkSettingsModal.open();
  }

  async setMCRSettings(): Promise<void> {
    await this.MCRModal.open();
  }

  setHullCoating(): void {
    this.HullCoatingModal.open();
  }

  openManageEventsModal(): void {
    this.manageEventsModal = true;
  }

  minutesDiff(fromDate: Date, toDate: Date): number {
    const diff = (fromDate.getTime() - toDate.getTime()) / 1000 / 60;

    return Math.abs(Math.round(diff));
  }

  formatEventDate(date: string): string {
    return moment.utc(date).format("DD.MMM YYYY");
  }

  //  TODO move to helpers
  autoGranularity(selectedTimeInMinutes: number): string {
    if (selectedTimeInMinutes <= 1440) {
      return "Raw";
    } else if (selectedTimeInMinutes <= 2880) {
      return "Minute";
    } else if (selectedTimeInMinutes <= 20160) {
      return "QuarterHour";
    } else if (selectedTimeInMinutes <= 129600) {
      return "Hour";
    }

    return "Day";
  }

  markerClicked(marker: MapMarker): void {
    this.SpeedLossChartCard.zoomIn(moment.utc(marker.lastExportDate).valueOf(), moment.utc(marker.lastExportDate).valueOf());
  }

  trackClicked(dates: { trackDateStart: string; trackDateEnd: string }): void {
    this.SpeedLossChartCard.zoomIn(moment.utc(dates.trackDateStart).valueOf(), moment.utc(dates.trackDateEnd).valueOf());
  }

  onShipSpeedLogChartSelection(datesRange: { min: number; max: number }): void {
    // Chart -> Operational Profile sync is disabled for now:
    // this.OperationalProfileCard.setDateRange(datesRange);
  }

  syncSpeedLossChartDates(datesRange: { min: number; max: number; zoomedOut: boolean }): void {
    this.SpeedLossChartCard.zoomIn(datesRange.min, datesRange.max, datesRange.zoomedOut);
  }

  getTrendPeriodByFromDate(fromDate: string): SpeedLossStatistic | null {
    return this.speedLossStatistics.find((item, i) => item.fromDate === fromDate) ?? null;
  }

  getTrendPeriod(trendPeriod: SpeedLossStatistic, type: string): SpeedLossStatistic | null {
    if (!type) return trendPeriod;
    let currentTrendPeriod = null;
    this.speedLossStatistics.forEach((item, i) => {
      if (item.fromDate === trendPeriod.fromDate) {
        currentTrendPeriod = this.speedLossStatistics[type === "next" ? i + 1 : i - 1];
      }
    });
    return currentTrendPeriod;
  }

  getTrendPeriodMeta(trendPeriod: SpeedLossStatistic): TrendPeriodMeta {
    return {
      vesselId: this.vessel?.id ?? null,
      fromDate: trendPeriod.fromDate,
      toDate: trendPeriod.toDate,
      nextPeriodStartDate: this.getTrendPeriod(trendPeriod, "next")?.fromDate ?? null,
      previousPeriodStartDate: this.getTrendPeriod(trendPeriod, "previous")?.fromDate ?? null,
      benchmark: trendPeriod.benchmark,
      trendEndValue: trendPeriod.trendEndValue,
      trendLine: trendPeriod.trendLine,
      speedLossHistory: this.speedLossHistory.filter(
        item => moment.utc(item.timestamp).valueOf() >= moment.utc(trendPeriod.fromDate).valueOf() && moment.utc(item.timestamp).valueOf() <= moment.utc(trendPeriod.toDate).valueOf()
      ),
    };
  }

  onTrendPeriodChanged(periodStartDate: string): void {
    const trendPeriod = this.getTrendPeriodByFromDate(periodStartDate);
    if (!trendPeriod) return;
    this.SpeedLossTrendDetailsModal.updatePeriod(this.getTrendPeriodMeta(trendPeriod));
  }

  //  @Hooks
  async created(): Promise<void> {
    if (!this.vessel || !this.featureEnabled) return;
    await this.fetchData();
  }

  beforeDestroy(): void {
    Fouling.CLEAR_SEA_SURFACE_TEMPERATURE();
  }
}
